<template>
  <v-sheet class="custom-border border mx-auto">
    <section class="d-flex pa-3 align-end justify-space-between">
      <section class="d-flex flex-wrap align-end" >
        <v-text-field
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          placeholder="Search student"
          hide-details
          class="mr-3 my-2 poppins"
          v-on:input="filterChange($event)"
        />
        <section class="d-flex flex-column my-2 mr-3">
          <h5 class="poppins f11 secondary-2--text  text-uppercase">
            Class Section
          </h5>
          <v-select outlined dense hide-details 
              :items="classes" 
              v-model="class_code" item-value="class_code"
              v-on:change="setClass()"
              return-object
            >
            <template slot="item" slot-scope="data">
              <div class="">
                <span class="secondary--text">{{data.item.class_code}}-</span>
                <span class="secondary-3--text">{{data.item.class_name}} </span>
              </div>
            </template>
            <template slot="selection"  slot-scope="data">
                <div class="">
                  <span class="secondary--text">{{data.item.class_code}}-</span>
                  <span class="secondary-3--text">{{data.item.class_name}} </span>
                </div>
            </template>
          </v-select>
        </section>
      </section>
      <v-spacer />
      <v-btn :disabled="loading" color="success" right outlined v-if="$vuetify.breakpoint.mdAndUp" class="poppins my-2" @click="exportTable">
        <v-icon left>
          mdi-export-variant
        </v-icon>
        Export List
      </v-btn>
      <v-btn icon :disabled="loading" color="success" v-else class="poppins my-2" @click="exportTable">
        <v-icon left>
          mdi-export-variant
        </v-icon>
      </v-btn>
    </section>
    <v-divider></v-divider>
    <ag-grid-vue style="width: 100%; height: 520px;"
        class="ag-theme-alpine ag-theme-mycustomtheme poppins"
        :gridOptions="{
          pagination: true,
          columnDefs: headers,
          rowData: student_data,
          defaultColDef : {
            filter: true,
            suppressMovable: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: true,
          },
          paginationAutoPageSize: true,
        }"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        @grid-ready="onGridReady"
        :modules="gridmodules">
    </ag-grid-vue>
  </v-sheet>
</template>

<style scope>
  .ag-theme-mycustomtheme {
    font-family: 'Poppins';
    border: none
  }

  .ag-header-group-cell-label, .ag-header-cell-label {
    flex-direction: column !important;
    padding-left: 18px !important;
    text-align: center !important;
  }

  .ag-cell-value {
    text-align: center;
  }
  
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { mapActions, mapState } from 'vuex';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'

let vm = this

const NameComponent = {
  template: '<a :style="{color: params.color, left: params.left, position: params.position, paddingLeft: params.paddingLeft }" @click="nameClick()">{{params.value}}</a>',
  methods: {
    nameClick(){
      this.$router.push({ name: 'Instructor Student Outputs Overview', params: {class_code: this.$route.query.class_code, uuid: this.params.data.student_uuid}})
      //window.location.href = `/instructor/student-outputs/${this.class_code}/${this.params.data.student_uuid}`
    }
  }
};

export default {
  components: {
    AgGridVue,
    NameComponent
  },
  data: () =>({
    loading: false,
    class_code: null,
    headers: [],
    filter: [
      { text: 'ALL', value: 'all' },
      { text: 'Topic', value: 'topic' },
      { text: 'Subtopic', value: 'sub_topic' }
    ],
    gridApi: null,
    gridmodules: [ExcelExportModule],
    student_data: [],
    colElements: null,
    selectedCourse: null,
    search: '',
    overlayLoadingTemplate : '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>'
  }),
  mounted(){
    //if(this.tenant === 'general_education') {
      this.setInitial() 
    // } else {
    //   this.getCourses()
    // }
  },
  methods: {
    ...mapActions('instructor', ['getStudentOutputClass', 'getStudentOutputCourse', 'getStudentOutputModules', 'getStudentOutput', 'getStudentOutputAssessments']),

    onGridReady(params) {
      this.gridApi = params.api;    
    },

    setInitial(){
      this.getStudentOutputClass().then(()=>{
        if(this.$route.query.class_code){
          this.class_code = this.classes.find(item => item.class_code === this.$route.query.class_code)
          this.$router.replace({ query: { class_code: this.class_code.class_code, timestamp: Date.now()} })
        } else {
          this.class_code = this.classes[0]
          this.$router.replace({ query: { class_code: this.class_code.class_code, timestamp: Date.now()} })
        }
        
      })
    },

    setClass(){
      this.$router.replace({ query: { class_code: this.class_code.class_code, timestamp: Date.now()} })
    },

    filterChange(e){
      this.gridApi.setQuickFilter(e)
    },

    getClasses(){
      this.loading = true
      this.getStudentOutputClass().then(()=>{
        this.class_code = this.classes[0]
        this.loading = false
        this.getCourse()
      })
    },

    getCourses(){
      this.headers = []
      this.student_data = []
      let _class_code = this.$route.query.class_code
      
      this.gridApi.showLoadingOverlay();
      this.getStudentOutputCourse({ class_code: _class_code, tenant: this.tenant }).then(()=>{
        this.headers.push(
          {
            colId: '', 
            headerName: 'Name', 
            field: "name", 
            pinned: 'left', 
            lockPosition: 'left', 
            headerComponentParams: { template: this.setPoints(null) },
            cellRenderer: 'NameComponent',
            cellRendererParams: {
              color: 'primary',
              left: '0',
              position: 'absolute',
              paddingLeft: '20px'
            },
            getQuickFilterText: (params) => {
              return params.value;
            },
          }
        )

        this.courses.forEach(course => {
          this.headers.push(
            {
              colId: course.uuid, 
              headerName: course.title, 
              field: course.title, 
              headerComponentParams: { template: this.setPoints(null) }
            }
          )
        })

        this.headers.push(
          {
            colId: '', 
            headerName: 'Final Grade', 
            field: "final_grade", 
            // pinned: 'right', 
            // lockPosition: 'right', 
            headerComponentParams: { template: this.setPoints(null) }
          }
        )

        this.getStudentOutput({ class_category_id: this.class_code ? this.class_code.id : null, tenant: this.tenant }).then(()=>{
          this.student_output.forEach(_student => {
            let _student_uuid = _student.uuid
            let _name = `${_student.first_name ? _student.first_name : 'Learner'} ${_student.last_name ? _student.last_name : 'Account'} ${_student.suffix ? _student.suffix : ''}`
            let _scores = {}
            let _student_course = _student.enrollments
            let _my_assessment_score_total = 0
            let _overall_assessment_score_total = 0
            _student_course.forEach(_course => {
              if(_course.course) {
                let _my_assessment_score = 0
                let _overall_assessment_score = parseFloat(_course.course.overall_assessment_score ? _course.course.overall_assessment_score : 0)
                let _assessments = _course.course.my_assessment_score.filter(_user => _user.user_id === _student.id)
                
                if(_assessments.length > 0){
                  _assessments.forEach(i => {
                    _my_assessment_score += parseFloat(i.score ? i.score : 0)
                  })
                }

                if(_my_assessment_score === 0 && _overall_assessment_score === 0) {
                  _scores[_course.course.title] = ""
                } else {
                  _scores[_course.course.title] = ((_my_assessment_score / _overall_assessment_score) * 100).toFixed(2)
                }

                _my_assessment_score_total += _my_assessment_score
                _overall_assessment_score_total += _overall_assessment_score
              }
            })

            if(_my_assessment_score_total === 0 && _overall_assessment_score_total === 0) {
              this.student_data.push(
                {
                  student_uuid: _student_uuid,
                  name: _name,
                  ..._scores,
                  final_grade: ''
                }
              )
            } else {
              this.student_data.push(
                {
                  student_uuid: _student_uuid,
                  name: _name,
                  ..._scores,
                  final_grade: ((_my_assessment_score_total / _overall_assessment_score_total) * 100 ).toFixed(2)
                }
              )
            }
          }) 
          this.gridApi.setColumnDefs(this.headers)
          this.gridApi.setRowData(this.student_data)
          this.gridApi.refreshHeader()

          if(this.headers.length <= 5 && !this.$vuetify.breakpoint.smAndDown && !this.$vuetify.breakpoint.mobile) {
            this.gridApi.sizeColumnsToFit()
          }

          var vm = this;

          setTimeout(()=>{
            let _colElements = document.getElementsByClassName('ag-header-cell')
              Array.from(_colElements).forEach(function(elem){
                elem.getElementsByClassName('ag-header-cell-comp-wrapper')[0].addEventListener('click', (event) => {
                let course_uuid = elem.getAttribute('col-id');
                if(course_uuid !== "back") {
                  vm.$router.replace({ query: { class_code: vm.$route.query.class_code, course_uuid: course_uuid, timestamp: Date.now() } })
                } else {
                  vm.$router.replace({ query: {} })
                }
              });
            })
            
            this.gridApi.hideOverlay();
          }, 1000); 
        })
      })
    },

    getModules(){
      let _course_uuid = this.$route.query.course_uuid
      this.gridApi.showLoadingOverlay();
      this.getStudentOutputModules({class_category_id: this.class_code.id, course_uuid: _course_uuid}).then(() => {
        let _module_uuid = []
        this.student_data = []
        if(this.modules.length > 0) {

          let index = this.modules[0].enrollments.findIndex(_course => _course.course && _course.course.uuid === _course_uuid)
          let _courseTitle = this.modules[0].enrollments[index].course.title
          this.headers = [{ 
            colId: 'back', 
            headerName: 
            _courseTitle, 
            field: "name", 
            pinned: 'left', 
            lockPosition: 'left', 
            headerComponentParams: { template: this.setCourseBack() },
            cellRenderer: 'NameComponent',
            cellRendererParams: {
              color: 'primary',
              left: '0',
              position: 'absolute',
              paddingLeft: '20px'
            }
          }]
          this.modules[0].enrollments[index].course.modules.forEach(_module => {
            /** set headers */
            this.headers.push(
              { colId: _module.uuid, headerName: _module.title ? _module.title : "Untitled Module", field: _module.title, align: "center", headerComponentParams: { template: this.setPoints(null) } }
            )
            _module_uuid.push(_module.uuid)
          })
          this.headers.push(
            // {
            //   colId: '', 
            //   headerName: 'Final Grade', 
            //   field: "final_grade", 
            //   align: "center",
            //   headerComponentParams: { template: this.setPoints(null) }
            // },
            {
              colId: '', 
              headerName: 'Training Hrs', 
              field: "training_hrs", 
              align: "center",
              headerComponentParams: { template: this.setPoints(null) }
            },
          )

          /** set user info per modules */
          this.modules.forEach(_student => {
            let _student_uuid = _student.uuid
            let _name = `${_student.first_name ? _student.first_name : 'Learner'} ${_student.last_name ? _student.last_name : 'Account'} ${_student.suffix ? _student.suffix : ''}`
            let _scores = {}

            let index = _student.enrollments.findIndex(_course => _course.course && _course.course.uuid === _course_uuid)
            if(index !== -1 && _student.enrollments[index].course){
              _student.enrollments[index].course.modules.forEach(_module => {
                let _sum = 0
                let assessments = _module.my_assessment_score.filter(_user => _user.user_id === _student.id)
                assessments.forEach(_test => {
                  _sum += parseFloat(_test.score ? _test.score : 0)
                })

                if((parseInt(_sum) === 0 && parseInt(_module.assessment_overall_score) === 0) || !_module.assessment_overall_score) {
                  _scores[_module.title] = ""
                } else {
                  _scores[_module.title] = ((_sum / parseFloat(_module.assessment_overall_score)) * 100).toFixed(2)
                }
              })

              this.student_data.push(
                {
                  student_uuid: _student_uuid,
                  name: _name,
                  ..._scores,
                  training_hrs: `${_student.enrollments[index].course.training_hours} hrs` 
                }
              )
            } else {
              this.student_data.push(
                {
                  student_uuid: _student_uuid,
                  name: _name,
                }
              )
            }
          })

          this.gridApi.setColumnDefs(this.headers)
          this.gridApi.setRowData(this.student_data)
          this.gridApi.refreshHeader()

          var vm = this;

          setTimeout(()=>{
            let _colElements = document.getElementsByClassName('ag-header-cell')
              Array.from(_colElements).forEach(function(elem){
                elem.getElementsByClassName('ag-header-cell-comp-wrapper')[0].addEventListener('click', (event) => {
                let module_uuid = elem.getAttribute('col-id');
                if(module_uuid !== "back") {
                  vm.$router.replace({ query: { class_code: vm.$route.query.class_code, course_uuid: vm.$route.query.course_uuid, module_uuid: module_uuid, timestamp: Date.now() } })
                } else {
                  vm.$router.replace({ query: null })
                }
              });
            })
            this.gridApi.hideOverlay();
          }, 1000); 
        }
      })
    },

    getAssessments() {
      let _course_uuid = this.$route.query.course_uuid
      let _module_uuid = this.$route.query.module_uuid

      this.headers = []
      this.student_data = []

      this.gridApi.showLoadingOverlay();
      this.getStudentOutputAssessments({
        course_uuid: _course_uuid,
        class_category_id: this.class_code.id,
        module_uuid: _module_uuid
      }).then(() => {
        
        this.assessments.forEach((_student, i) => {
          /** set headers */
          if(i === 0) {
            let _course_index = _student.enrollments.findIndex(_course => _course.course && _course.course.uuid === _course_uuid)
            let _module_index = _student.enrollments[_course_index].course.modules.findIndex(_module => _module.uuid === _module_uuid)
            let _module_title = _student.enrollments[_course_index].course.modules[_module_index].title

            this.headers = [{ 
              colId: 'back', 
              headerName: _module_title, 
              field: "name", 
              pinned: 'left', 
              lockPosition: 'left', 
              headerComponentParams: { template: this.setCourseBack() },
              cellRenderer: 'NameComponent',
              cellRendererParams: {
                color: 'primary'
              }
            }]

            _student.enrollments[_course_index].course.modules[_module_index].assessments.forEach(_item => {
              this.headers.push(
                { colId: _item.uuid, headerName: _item.title ? _item.title : 'Untitled Assessment', field: _item.title, align: "center", headerComponentParams: { template: this.setPoints(_item.assessment_overall_score) } }
              )
            })
          }

          /** set student info */ 

          let _student_uuid = _student.uuid
          let _name = `${_student.first_name ? _student.first_name : 'Learner'} ${_student.last_name ? _student.last_name : 'Account'} ${_student.suffix ? _student.suffix : ''}`
          let _scores = {}

          let _course_index = _student.enrollments.findIndex(_course => _course.course && _course.course.uuid === _course_uuid)
          if(_course_index !== -1 && _student.enrollments[_course_index].course) {
            let _module_index = _student.enrollments[_course_index].course.modules.findIndex(_module => _module.uuid === _module_uuid)
            _student.enrollments[_course_index].course.modules[_module_index].assessments.forEach(_item => {
              let _assessments = _item.assessment_scores.filter(_user => _user.user_id === _student.id)
              let _sum = 0
              _assessments.forEach(_test => {
                _sum += parseFloat(_test.score ? _test.score : 0)
              })

              if((parseInt(_sum) === 0 && parseInt(_item.assessment_overall_score) === 0) || !_item.assessment_overall_score) {
                _scores[_item.title] = ""
              } else {
                _scores[_item.title] = ((_sum / parseFloat(_item.assessment_overall_score)) * 100).toFixed(2)
              }
            })

            this.student_data.push(
              {
                student_uuid: _student_uuid,
                name: _name,
                ..._scores,
              }
            )
          } else {
            this.student_data.push(
              {
                student_uuid: _student_uuid,
                name: _name,
              }
            )
          }
           
        })

        this.gridApi.setColumnDefs(this.headers)
        this.gridApi.setRowData(this.student_data)
        this.gridApi.refreshHeader()

        var vm = this;

        setTimeout(()=>{
          let _colElements = document.getElementsByClassName('ag-header-cell')
            Array.from(_colElements).forEach(function(elem){
              elem.getElementsByClassName('ag-header-cell-comp-wrapper')[0].addEventListener('click', (event) => {
              let module_uuid = elem.getAttribute('col-id');
              if(module_uuid === "back") {
                vm.$router.replace({ query: { class_code: vm.$route.query.class_code, course_uuid: vm.$route.query.course_uuid, timestamp: Date.now() } })
              }
            });
          })
        }, 1000);
        this.gridApi.hideOverlay();
      })
    },

    exportTable(){
      this.gridApi.exportDataAsExcel();
    },

    setPoints(points) {
        
      return '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <a class="header-text"> <span ref="eText" class="ag-header-cell-text f16" role="columnheader"></span></a>' + 
            '    <span class="points_span fw400 f14"> '+ (points ? `out of ${points}` : '') + '<span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            '  </div>' +
            '</div>'
    },

    setCourseBack() {
      return '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <a class="header-text">< &nbsp;<span ref="eText" class="ag-header-cell-text f16" role="columnheader"></span></a>' + 
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>'
    },
  },
  computed:{
    ...mapState('instructor', {
      classes: (state) => state.classes,
      courses: (state) => state.courses,
      modules: (state) => state.modules,
      student_output: (state) => state.student_output,
      assessments: (state) => state.assessments
    }),
    ...mapState({
      tenant: (state) => state.tenant
    })
  },
  watch: {
    $route(to, from){
      if(to.name === "Instructor Student Outputs") {
          if(Object.keys(to.query).length === 0) {
            this.setInitial()
          }
          if(to.query.class_code && !to.query.course_uuid && !to.query.module_uuid) {
            this.getCourses()
          }
          if(to.query.class_code && to.query.course_uuid && !to.query.module_uuid) {
            this.getModules()
          }
          if(to.query.class_code && to.query.course_uuid && to.query.module_uuid) {
            this.getAssessments()
        }
      }
    },
    search(val) {
    }
  }
}
</script>